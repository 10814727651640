<template>
  <div v-if="renderComponent">
    <b-card
      class="mb-0"
      :title="category.category"
      v-for="(category, index) in filters"
      :key="index"
    >
      <b-row v-for="(field, idx) in category.fields" :key="idx">
        <b-col cols="12">
          <b-form-group class="mb-2">
            <label>{{ field.label }}</label>
            <v-select
              v-if="field.type === 'select'"
              :id="`field-${field.key}`"
              v-model="filterValues[field.key]"
              :options="field.options"
              class="placeholder-dark"
              :placeholder="field.placeholder || ''"
            />

            <b-form-input
              v-else-if="field.type === 'text'"
              :id="`field-${field.key}`"
              v-model="filterValues[field.key]"
              class="form-control placeholder-dark"
            />

            <div v-else-if="field.type === 'slider'">
              <vue-slider
                v-model="filterValues[field.key]"
                :min="0"
                :max="50"
                tooltip="always"
                :interval="1"
                class="mt-2 pt-2 px-1 cc-slider font-weight-bolder"
                :tooltip-style="{ background: '#3fe4c6', color: 'black', borderColor: '#3fe4c6' }"
                :dot-style="{ background: '#06C2A1' }"
                :process-style="{ background: '#06C2A1' }"
                :lazy="true"
              />
            </div>

            <flat-pickr
              v-else
              :id="`field-${field.key}`"
              v-model="filterValues[field.key]"
              class="form-control placeholder-dark"
              :config="{ mode: 'range' }"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="px-2">
      <b-col cols="12" class="d-flex flex-row justify-content-start">
        <b-button
          variant="outline-primary"
          @click="clearFilters"
          class="text-dark font-weight-bolder d-flex justify-content-center align-items-center"
          block
        >
          <feather-icon icon="RotateCcwIcon" size="18" class="mr-1" /> Limpar filtros
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BButton, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue'
import Filters from '../components/Filters.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'Filters',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    Filters,
    vSelect,
    flatPickr,
    VueSlider,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      renderComponent: true,
      filterValues: {},
      statusOptions: [],
    }
  },
  async mounted() {
    await this.setDefaultValues()
  },
  computed: {
    filters() {
      return this.$route.meta.indexObject?.filters
    },
  },
  methods: {
    setDefaultValues() {
      this.filters?.forEach((category) => {
        category.fields.forEach((field) => {
          if (field.type === 'select' && field.options) {
            const defaultOption = field.options.find((opt) => opt.selected)
            if (defaultOption) {
              setTimeout(() => {
                this.$set(this.filterValues, field.key, defaultOption.value)
              }, 50)
            }
          }
        })
      })
    },

    clearFilters() {
      this.filterValues = {}
    },
    async forceRerender() {
      this.renderComponent = false
      await this.$nextTick()
      this.renderComponent = true
    },
  },
  watch: {
    filterValues: {
      handler() {
        let filters = {}

        Object.entries(this.filterValues).filter((obj) => {
          let key = obj[0]
          let value =
            typeof obj[1] === 'string' || typeof obj[1] === 'number' ? obj[1] : obj[1]?.value || ''

          if (typeof value === 'string' && value.match(/[0-9] to [0-9]/g))
            value = value.split(' to ')
          if (value) filters[key] = value
        })

        this.$emit('updateFilterValues', filters)
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.placeholder-dark {
  &::placeholder {
    color: #66788b;
    opacity: 1;
  }
}

.cc-slider {
  height: 8px !important;
}
</style>
