<template>
  <b-card-actions class="mt-2 emission-card-border" title="Notas e Recibos" action-collapse>
    <div v-if="Object.keys(result).length">
      <b-button-group class="text-nowrap mr-2 mb-2" v-if="canShow">
        <b-button
          :variant="selectedButtonVariant[year]"
          class="text-dark"
          @click="selectButton(year)"
          v-for="year in Object.keys(result)"
          :key="year"
        >
          <strong>{{ year }}</strong>
        </b-button>
      </b-button-group>

      <b-tabs content-class="pt-1">
        <b-tab
          class="text-dark font-weight-bolder"
          v-for="months in Object.keys(result[selectedButton])"
          :key="months"
        >
          <template #title>
            <span class="font-weight-bolder text-dark">{{ months }}</span>
          </template>

          <b-table
            bordered
            responsive="sm"
            :items="result[selectedButton][months]"
            :fields="fileField"
            class="mb-0 pus-table"
          >
            <template #cell(url_nf)="data">
              <a :href="data.item.url_nf" target="_blank">
                <feather-icon icon="DownloadIcon" size="22" class="text-success" />
              </a>
            </template>
            <template #cell(url_recibo)="data">
              <a :href="data.item.url_recibo" target="_blank" >
                <feather-icon icon="DownloadIcon" size="22" class="text-success" />
              </a>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </div>
    <div class="border-top pt-2" v-else>
      <span class="text-center">
        <feather-icon icon="AlertCircleIcon" size="20" class="text-muted mr-50" />
        Não há notas e recibos cadastrados no momento.
      </span>
    </div>
  </b-card-actions>
</template>

<script>
import { BTab, BTabs, BCard, BTable, BButton, BButtonGroup } from 'bootstrap-vue'
import { BCardActions } from '@/@core/components/b-card-actions'
import { moneyFormatDatatable } from '@/@core/comp-functions/data_visualization/datatable'

export default {
  name: 'NotesAndReceipts',
  components: {
    BTab,
    BTabs,
    BCard,
    BTable,
    BButton,
    BCardActions,
    BButtonGroup,
  },
  props: {
    expenses: {
      type: Object,
    },
  },
  data() {
    return {
      selectedButton: '2023',
      fileField: [
        {
          key: 'nome',
          label: 'Despesa',
        },
        {
          key: 'prestador',
          label: 'Prestador',
        },
        {
          key: 'valor',
          label: 'Valor',
          formatter: moneyFormatDatatable
        },
        {
          key: 'data_vencimento',
          label: 'Data de Vencimento',
        },
        {
          key: 'data_pagamento',
          label: 'Data de Pagamento',
        },
        {
          key: 'url_nf',
          label: 'NF',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'url_recibo',
          label: 'Recibo',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      result: {},
      months: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    }
  },
  async mounted() {
    await this.setUpExpenses()
  },
  methods: {
    async setUpExpenses() {
      Object.keys(this.expenses).forEach((year) => {
        this.$set(this.result, year, {})
        Object.keys(this.expenses[year]).forEach((month) => {
          this.$set(this.result[year], this.months[month - 1], this.expenses[year][month])
        })
      })
      this.selectedButton = Object.keys(this.result)[0]
    },
    selectButton(type) {
      this.selectedButton = type
    },
  },
  computed: {
    canShow() {
      return !!this.result[Object.keys(this.expenses)[0]]
    },
    selectedButtonVariant() {
      let types = {}
      Object.keys(this.expenses).forEach((year) => {
        types[year] = 'outline-primary'
      })

      types[this.selectedButton] = 'primary'

      return types
    },
  },
}
</script>
